import { useRouteError } from "react-router-dom";
import "../App.css";
import { SekmeBasligi } from "../GeneralFunctions";

export default function ErrorPage() {

  SekmeBasligi('Sayfa Bulunamadı ! | Mahsen Cafes');

  const error = useRouteError();
  console.error(error);

  return (
    <div id="error-page" style={{
      textAlign: 'center',
      marginTop: '20%'
    }}>
      <h1>Üzgünüz</h1>
      <p>Bu bir Hata Sayfasıdır.</p>
      <p>
        <i>{error.statusText || error.message}</i>
      </p>
    </div>
  );
}