import { useRef, useEffect } from "react";
import "./CSS/MenuPage.css";
import ReactGA from "react-ga4";
import { SekmeBasligi } from "../GeneralFunctions";
import AdBanner from "../components/Ads";

ReactGA.initialize("G-6XMNSTEL6D");

ReactGA.send({
  hitType: "pageview",
  page: window.location.pathname,
});

function MenuPage() {
  SekmeBasligi("Mahsen Cafe | Menü");

  const refMain = useRef(null);
  const ref = useRef(null);
  const cat2 = useRef(null);
  const cat3 = useRef(null);
  const cat4 = useRef(null);
  const cat5 = useRef(null);
  const cat6 = useRef(null);

  const Sayfa = () => {
    refMain.current?.scrollIntoView({ behavior: "smooth" });
  };

  const DONER = () => {
    ref.current?.scrollIntoView({ behavior: "smooth" });
  };

  const KOFTE = () => {
    cat2.current?.scrollIntoView({ behavior: "smooth" });
  };

  const TOST = () => {
    cat3.current?.scrollIntoView({ behavior: "smooth" });
  };

  const KUMRU = () => {
    cat4.current?.scrollIntoView({ behavior: "smooth" });
  };

  const MENEMEN = () => {
    cat5.current?.scrollIntoView({ behavior: "smooth" });
  };

  const İCECEKLER = () => {
    cat6.current?.scrollIntoView({ behavior: "smooth" });
  };

  return (
    <div className="menu-page" ref={refMain}>
      <div className="box-kategori">
        <div className="item">
          <a onClick={DONER}>DÖNER</a>
        </div>
        <div className="item">
          <a onClick={KOFTE}>KÖFTE</a>
        </div>
        <div className="item">
          <a onClick={TOST}>TOST</a>
        </div>
        <div className="item">
          <a onClick={KUMRU}>KUMRU</a>
        </div>
        <div className="item">
          <a onClick={MENEMEN}>MENEMEN</a>
        </div>
        <div className="item">
          <a onClick={İCECEKLER}>İÇECEKLER</a>
        </div>
      </div>

      <div className="banner">
        <img src="assets/icon/Timmerman-removebg-preview.png" />
        <div className="banner-content">
          <p>
            <span className="head-txt-1">Menu </span>
          </p>
        </div>
      </div>

      <div className="menu-container">
        <div ref={ref} className="menu-baslik">
          DÖNER
        </div>
        <div className="menu-urun">
          <input type="image" img src={"assets/doner/tam-doner.jpg"} />
          <div className="baslik">TAM DÖNER</div>
          <div className="alt-baslik">Tam</div>
          <div className="fiyat">140,00 TL</div>
        </div>
        <div className="menu-urun">
          <input type="image" img src="assets/doner/yarim-doner.jpg" />
          <div className="baslik">YARIM DÖNER</div>
          <div className="alt-baslik">Yarım</div>
          <div className="fiyat">70,00 TL</div>
        </div>
        <div className="menu-urun">
          <input type="image" img src="assets/doner/yarim-doner.jpg" />
          <div className="baslik">ÜÇ PARÇA DÖNER</div>
          <div className="alt-baslik">3 Parça</div>
          <div ref={cat2} className="fiyat">
            100,00 TL
          </div>
        </div>

        <div className="menu-baslik">KÖFTE</div>
        <div className="menu-urun">
          <input type="image" img src="assets/kofte/porsiyon-kofte.jpg" />
          <div className="baslik">PORSİYON KÖFTE</div>
          <div className="alt-baslik">1 Tabak</div>
          <div className="fiyat">200,00 TL</div>
        </div>
        <div className="menu-urun">
          <input type="image" img src="assets/kofte/tam-kofte.jpg" />
          <div className="baslik">TAM KÖFTE</div>
          <div className="alt-baslik">Tam</div>
          <div className="fiyat">180,00 TL</div>
        </div>
        <div className="menu-urun">
          <input type="image" img src="assets/kofte/yarim-kofte.jpg" />
          <div className="baslik">YARIM KÖFTE</div>
          <div className="alt-baslik">Yarım</div>
          <div className="fiyat">100,00 TL</div>
        </div>
        <div className="menu-urun">
          <input type="image" img src="assets/kofte/yarim-kofte.jpg" />
          <div className="baslik">ÜÇ PARÇA KÖFTE</div>
          <div className="alt-baslik">3 Parça</div>
          <div ref={cat3} className="fiyat">
            140,00 TL
          </div>
        </div>

        <div className="menu-baslik">TOST</div>
        <div className="menu-urun">
          <input type="image" img src="assets/tost/tam-tost.jpg" />
          <div className="baslik">TAM TOST</div>
          <div className="alt-baslik">Tam</div>
          <div className="fiyat">140,00 TL</div>
        </div>
        <div className="menu-urun">
          <input type="image" img src="assets/tost/yarim-tost.png" />
          <div className="baslik">YARIM TOST</div>
          <div className="alt-baslik">Yarım</div>
          <div className="fiyat">70,00 TL</div>
        </div>
        <div className="menu-urun">
          <input type="image" img src="assets/tost/parca-tost.jpeg" />
          <div className="baslik">ÜÇ PARÇA TOST</div>
          <div className="alt-baslik">3 Parça</div>
          <div className="fiyat">100,00 TL</div>
        </div>
        <div className="menu-urun">
          <input type="image" img src="assets/tost/kasarli-tost.jpg" />
          <div className="baslik">KAŞARLI TOST</div>
          <div className="alt-baslik">Kaşarlı</div>
          <div ref={cat4} className="fiyat">
            50,00 TL
          </div>
        </div>

        <div className="menu-baslik">KUMRU</div>
        <div className="menu-urun">
          <input type="image" img src="assets/kumru/tam-kumru.png" />
          <div className="baslik">TAM KUMRU</div>
          <div className="alt-baslik">Tam</div>
          <div className="fiyat">150,00 TL</div>
        </div>
        <div className="menu-urun">
          <input type="image" img src="assets/kumru/tam-kumru.png" />
          <div className="baslik">YARIM KUMRU</div>
          <div className="alt-baslik">Yarım</div>
          <div className="fiyat">80,00 TL</div>
        </div>
        <div className="menu-urun">
          <input type="image" img src="assets/kumru/parca-kumru.jpg" />
          <div className="baslik">ÜÇ PARÇA KUMRU</div>
          <div className="alt-baslik">3 Parça</div>
          <div ref={cat5} className="fiyat">
            100,00 TL
          </div>
        </div>

        <div className="menu-baslik">MENEMEN</div>
        <div className="menu-urun">
          <input type="image" img src="assets/menemen/sucuklu-menemen.jpg" />
          <div className="baslik">SUCUKLU MENEMEN</div>
          <div className="alt-baslik">Sucuklu</div>
          <div className="fiyat">100,00 TL</div>
        </div>
        <div className="menu-urun">
          <input type="image" img src="assets/menemen/menemen.jpg" />
          <div className="baslik">MENEMEN</div>
          <div className="alt-baslik">Sade</div>
          <div ref={cat6} className="fiyat">
            85,00 TL
          </div>
        </div>

        <div className="menu-baslik">İÇECEKLER</div>
        <div className="menu-urun">
          <input
            type="image"
            img
            className="icecek-kat"
            src="assets/icecekler/sise-kola.jpg"
          />
          <div className="baslik">ŞİŞE KOLA</div>
          <div className="alt-baslik">200 Ml</div>
          <div className="fiyat">25,00 TL</div>
        </div>
        <div className="menu-urun">
          <input
            type="image"
            img
            className="icecek-kat"
            src="assets/icecekler/kutu-kola.jpeg"
          />
          <div className="baslik">KUTU KOLA</div>
          <div className="alt-baslik">330 Ml</div>
          <div className="fiyat">30,00 TL</div>
        </div>
        <div className="menu-urun">
          <input
            type="image"
            img
            className="icecek-kat"
            src="assets/icecekler/ice-tea.png"
          />
          <div className="baslik">ICE TEA</div>
          <div className="alt-baslik">330 Ml</div>
          <div className="fiyat">30,00 TL</div>
        </div>
        <div className="menu-urun">
          <input
            type="image"
            img
            className="icecek-kat"
            src="assets/icecekler/gazoz.png"
          />
          <div className="baslik">GAZOZ</div>
          <div className="alt-baslik">330 Ml</div>
          <div className="fiyat">25,00 TL</div>
        </div>
        <div className="menu-urun">
          <input
            type="image"
            img
            className="icecek-kat"
            src="assets/icecekler/sade-soda.jpg"
          />
          <div className="baslik">SADE SODA</div>
          <div className="alt-baslik">200 Ml</div>
          <div className="fiyat">10,00 TL</div>
        </div>
        <div className="menu-urun">
          <input
            type="image"
            img
            className="icecek-kat"
            src="assets/icecekler/meyveli-soda.jpg"
          />
          <div className="baslik">MEYVELİ SODA</div>
          <div className="alt-baslik">200 Ml</div>
          <div className="fiyat">15,00 TL</div>
        </div>
        <div className="menu-urun">
          <input
            type="image"
            img
            className="icecek-kat"
            src="assets/icecekler/salgam.jpg"
          />
          <div className="baslik">ŞALGAM</div>
          <div className="alt-baslik">1000 Ml</div>
          <div className="fiyat">25,00 TL</div>
        </div>
        <div className="menu-urun">
          <input
            type="image"
            img
            className="icecek-kat"
            src="assets/icecekler/ayran.jpg"
          />
          <div className="baslik">BÜYÜK AYRAN</div>
          <div className="alt-baslik">300 Ml</div>
          <div className="fiyat">15,00 TL</div>
        </div>
        <div className="menu-urun">
          <input
            type="image"
            img
            className="icecek-kat"
            src="assets/icecekler/ayran.jpg"
          />
          <div className="baslik">KÜÇÜK AYRAN</div>
          <div className="alt-baslik">200 Ml</div>
          <div className="fiyat">10,00 TL</div>
        </div>
        <div className="menu-urun">
          <input
            type="image"
            img
            className="icecek-kat"
            src="assets/icecekler/turk-kahvesi.jpg"
          />
          <div className="baslik">TÜRK KAHVESİ</div>
          <div className="alt-baslik">Sıcak Kahve</div>
          <div className="fiyat">25,00 TL</div>
        </div>

        <AdBanner/>
      </div>

      <button onClick={Sayfa} className="yukari-cik">
        <img src="assets/icon/arrow-up.png"></img>
      </button>
    </div>
  );
}

export default MenuPage;
