import React from "react";
import "./HomePage.css";
import {Route, Link, Routes} from 'react-router-dom';
import ReactGA from "react-ga4";

ReactGA.initialize("G-6XMNSTEL6D");

ReactGA.send({
  hitType: "pageview",
  page: window.location.pathname,
});

const HomePage = () => {
    return (
        <>
            <div className='banner'>
                <img src='assets/sg-foodanddrink-popularmobilecoffeevanopenscafeinleckhampton-2023-news-ec.jpg' />
                <div className='banner-content'>
                    <p>
                        <span className='head-txt-1'>MAHSEN </span>
                        <span className='head-txt-2'>KAFE & KAHVALTILIK</span>
                    </p>
                    <div className='btn-container'>
                        <a className='btn' href='tel:905539195073'>Bizi Arayın</a>
                    </div>
                </div>
            </div>

            <div className='service-box'>
                <div className='box-container'>
                    <div className='box'>
                        <h3>Mahsen Cafe</h3>
                        <p>Dokuz Eylül, Ulaştırma Cd. No:9/A <b><br/>Gaziemir / İZMİR</b></p>
                        <p><b>Cafe:</b> 0553 919 5073</p>
                        <p><b>Email:</b> mahsencafe@gmail.com</p>
                        <a href='/İletisimPage' className='btn'>Bize Ulaşın</a>
                    </div>
                    <div className='box'>
                        <img src='assets/tea-time.jpg' alt='' />
                    </div>
                </div>

                <hr className='cizgi'></hr>

                <p className='yazi'>Keyfin, huzurun, neşenin, lezzetli yemeklerin, eşsiz sohbetlerin, tatlı kaçamakların, heyecanlı buluşmaların, neşeli kutlamaların mekanı Mahsen Cafe.</p>
            </div>

            <div className='footer-bottom'>
                <p>copyright Premadesoft Tüm Hakları Saklıdır. <span>PREMADE SOFT</span></p>
            </div>
        </>
    )
}

export default HomePage;