import React from "react";
import { createRoot } from "react-dom/client";
import {Route, Link, Routes} from 'react-router-dom';
import { createBrowserRouter, RouterProvider, Outlet} from "react-router-dom";
import Navbar from "./components/Navbar";
import "./App.css";
import ErrorPage from "./routes/ErrorPage";
import HomePage from "./HomePage/HomePage";
import MenuPage from "./routes/MenuPage";
import İletisimPage from "./routes/İletisim";

const AppLayout = () => {
  return (
    <>
      <Navbar />
      <Outlet />
    </>
  );
};

const router = createBrowserRouter([
  {
    element: <AppLayout />,
    errorElement: <ErrorPage />,
    children: [
      {
        path: "/",
        element: <HomePage />,
      },
      {
        path: "MenuPage",
        element: <MenuPage />,
      },
      {
        path: "İletisimPage",
        element: <İletisimPage />,
      },
    ],
  },
]);

createRoot(document.getElementById("root")).render(
  <RouterProvider router={router} />
);