import { useRef, useEffect } from 'react';
import './CSS/İletisimPage.css';
import { PiPhoneCallBold } from "react-icons/pi";
import { AiOutlineMail } from "react-icons/ai";
import { SlLocationPin } from "react-icons/sl";
import { BiFoodMenu } from "react-icons/bi";
import { SekmeBasligi } from '../GeneralFunctions';

function İletisimPage() {

    SekmeBasligi('Mahsen Cafe | İletişim');
    return (
        <>
            <div className='banner'>
                <img src='assets/tr-kahvesi-banner.jpg' />
                <div className='banner-content'>
                    <p>
                        <span className='head-txt'>İLETİŞİM </span>
                    </p>
                </div>
            </div>

            <div className="card-iletisim-container">
                <div className="card-main-body">
                    <div className="row">
                        <div className="col-xl-3 col-lg-6">
                            <div className="card card-stats mb-4 mb-xl-0">
                                <div className="card-body">
                                    <div className="row">
                                        <div className="col">
                                            <h5 className="card-title text-uppercase text-muted mb-0">Telefon</h5>
                                            <a className="card-a-text" href="tel:905539195073">+90 553 919 5073</a>
                                        </div>
                                        <div className="col-auto">
                                            <div className="icon icon-shape bg-danger text-white rounded-circle shadow">
                                                <PiPhoneCallBold size="60" color='#fff' />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-xl-3 col-lg-6">
                            <div className="card card-stats mb-4 mb-xl-0">
                                <div className="card-body">
                                    <div className="row">
                                        <div className="col">
                                            <h5 className="card-title text-uppercase text-muted mb-0">E-Mail</h5>
                                            <span className="card-a-text">premadesoft@gmail.com</span>
                                        </div>
                                        <div className="col-auto">
                                            <div className="icon icon-shape bg-warning text-white rounded-circle shadow">
                                                <AiOutlineMail size="60" color='#fff' />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-xl-3 col-lg-6">
                            <div className="card card-stats mb-4 mb-xl-0">
                                <div className="card-body">
                                    <div className="row">
                                        <div className="col">
                                            <h5 className="card-title text-uppercase text-muted mb-0">Adres</h5>
                                            <a className="card-a-text" href>Dokuz Eylül, Ulaştırma Cd. No:9/A <b>Gaziemir / İzmir</b></a>
                                        </div>
                                        <div className="col-auto">
                                            <div className="icon icon-shape bg-green text-white rounded-circle shadow">
                                                <SlLocationPin size="60" color='#fff' />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <h2 className="harita-head">Haritada Biz</h2>
                    <div className="harita-container">
                        <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3129.9659862770923!2d27.135119076324255!3d38.326618480179945!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x14bbdfb37fff1c4d%3A0x15797ba1533e3313!2sMahsen%20Cafe!5e0!3m2!1str!2str!4v1706090589630!5m2!1str!2str" width={600} height={450} style={{ border: 0 }} allowFullScreen loading="lazy" referrerPolicy="no-referrer-when-downgrade" />
                    </div>
                </div>
            </div>

            <div className='footer-bottom'>
                <p>copyright Premadesoft Tüm Hakları Saklıdır. <span>PREMADE SOFT</span></p>
            </div>
        </>
    )
}

export default İletisimPage;